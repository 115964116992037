import queryString from "querystring";

import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { gql } from "apollo-boost";
import page from "page";
import { reactive, watchEffect } from "vue";

import { logos } from "../components/layout.vue";
import {
  CreateRegisterPayment,
  CreateRegisterPaymentVariables,
} from "./__generated__/CreateRegisterPayment";
import {
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables,
} from "./__generated__/PaymentMethodsQuery";
import {
  ValidateAppleMerchant,
  ValidateAppleMerchantVariables,
} from "./__generated__/ValidateAppleMerchant";
import {
  Environment,
  getMerchantSlug,
  getPaymentType,
  isSafari,
  setupAdyen,
} from "./utils";

export const paymentMethodsQuery = gql`
  query PaymentMethodsQuery(
    $amountInCent: Int!
    $shopperReference: String
    $slug: String!
  ) {
    paymentMethods(
      amountInCent: $amountInCent
      shopperReference: $shopperReference
      merchantSlug: $slug
    ) {
      groups {
        name
        types
      }
      paymentMethods {
        brands
        supportsRecurring
        details {
          key
          type
          items {
            id
            name
          }
        }
        name
        type
      }
    }
  }
`;

const createRegisterPaymentMutation = gql`
  mutation CreateRegisterPayment(
    $userId: String!
    $input: CreateRegisterPaymentInput!
    $merchantSlug: String!
  ) {
    registerWithPaymentSimple(
      userId: $userId
      input: $input
      merchantSlug: $merchantSlug
    ) {
      redirectURL
      resultCode
      shopperReference
    }
  }
`;

export const validateAppleMerchantMutation = gql`
  mutation ValidateAppleMerchant($validationURL: String) {
    validateAppleMerchant(validationURL: $validationURL) {
      epochTimestamp
      expiresAt
      merchantSessionIdentifier
      nonce
      merchantIdentifier
      domainName
      displayName
      signature
    }
  }
`;

export default {
  name: "Registration",
  props: {
    params: Object,
    title: String,
  },
  setup() {
    const merchant = getMerchantSlug();

    const state = reactive({
      paymentMethod: null,
      type: getPaymentType(),
      registrationLoading: false,
      nextPaymentMethod: "ideal",
    });

    const { mutate: validateMerchant } = useMutation<
      ValidateAppleMerchant,
      ValidateAppleMerchantVariables
    >(validateAppleMerchantMutation, null);

    const { mutate: createPayment } = useMutation<
      CreateRegisterPayment,
      CreateRegisterPaymentVariables
    >(createRegisterPaymentMutation, null);

    const { result, loading } = useQuery<
      PaymentMethodsQuery,
      PaymentMethodsQueryVariables
    >(paymentMethodsQuery, {
      amountInCent: 2,
      slug: merchant,
    });

    const data = useResult(result);

    watchEffect(() => {
      if (data.value) {
        console.log(data.value);

        setupAdyen(
          (paymentMethod) => {
            state.paymentMethod = paymentMethod.data.paymentMethod;
            if (state.type === "applepay") {
              register();
            }
          },
          data.value,
          async (url: string, env: Environment) => {
            try {
              const { data } = await validateMerchant({
                validationURL: url,
              });
              return data.validateAppleMerchant;
            } catch (error) {
              console.error(error);
              alert(error.message);
            }
          },
          {
            amount: 2,
            getAmount: () => 2,
            type: state.type as any,
          }
        );
      }
    });

    async function register() {
      if (!state.paymentMethod) {
        return;
      }

      try {
        state.registrationLoading = true;
        const { data } = await createPayment({
          userId: localStorage.getItem("userId"),
          merchantSlug: merchant,
          input: {
            paymentMethod: state.paymentMethod,
            returnURL:
              location.href
                .replace(/\/?$/, "")
                .replace("/cc", "")
                .replace("/applepay", "")
                .replace("/paywithgoogle", "")
                .replace("/ideal", "") + "/account-created",
          },
        });

        const {
          resultCode,
          shopperReference,
          redirectURL,
        } = data.registerWithPaymentSimple;

        // An credit card/applepay payment which has no redirect but rather the success code comes in the ajax response
        if (resultCode && resultCode.toLowerCase() === "authorised") {
          console.log(
            "Redirecting to ",
            `/${merchant}/account-created` +
              "?" +
              queryString.stringify({
                shopperReference,
                resultCode: "authorised",
              })
          );
          page.show(
            `/${merchant}/account-created` +
              "?" +
              queryString.stringify({
                shopperReference,
                resultCode: "authorised",
              })
          );
        } else {
          // An iDEAL payment
          window.location.href = redirectURL;
        }
      } catch (error) {
        alert(error.message);
      } finally {
        state.registrationLoading = false;
      }
    }

    function togglePaymentMode(evt) {
      evt.preventDefault();
      if (state.type === "card") {
        state.type = "ideal";
        state.nextPaymentMethod = isSafari ? "applepay" : "card";
      } else if (state.type === "ideal" && !isSafari) {
        state.type = "card";
        state.nextPaymentMethod = "ideal";
      } else if (state.type === "ideal" && isSafari) {
        state.type = "applepay";
        state.nextPaymentMethod = "card";
      } else {
        state.type = "card";
        state.nextPaymentMethod = "ideal";
      }
    }

    return {
      data,
      loading,
      state,
      logos,
      merchant,
      register,
      togglePaymentMode,
    };
  },

  template: require("./registration.vue.html"),
};
