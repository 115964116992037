import { useMutation, useQuery, useResult } from "@vue/apollo-composable";
import { gql } from "apollo-boost";
import { sumBy } from "lodash";
import page from "page";
import queryString from "query-string";
import { computed, onMounted, reactive, watch } from "vue";

import {
  CreatePaymentMutation,
  CreatePaymentMutationVariables,
} from "./__generated__/CreatePaymentMutation";
import { LinkedCardsQuery } from "./__generated__/LinkedCardsQuery";
import {
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables,
} from "./__generated__/PaymentMethodsQuery";
import { paymentMethodsQuery } from "./registration.vue";
import {
  Environment,
  getEnvironment,
  getMerchantSlug,
  setupAdyen,
} from "./utils";

const linkedCardsQuery = gql`
  query LinkedCardsQuery {
    admin_cards {
      shopperReference
      paymentMethod
      cardInfos
      User {
        email
      }
      Transaction {
        points
      }
    }
  }
`;

export const createPaymentMutation = gql`
  mutation CreatePaymentMutation($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      redirectURL
      shopperReference
      resultCode
    }
  }
`;

export default {
  name: "MerchantPage",
  template: require("./merchant.vue.html"),
  setup() {
    const merchant = getMerchantSlug();

    const state = reactive<{
      card: null | { paymentMethod: any; shopperReference: string };
      shopperReference: null | string;
      amountInCent: null | number;
      paymentMethod: any;
      successMessage: { shopperReference: string };
    }>({
      card: null,
      shopperReference: null,
      amountInCent: null,
      paymentMethod: null,
      successMessage: null,
    });

    const { mutate: createPayment } = useMutation<
      CreatePaymentMutation,
      CreatePaymentMutationVariables
    >(createPaymentMutation, null);

    const {
      result: linkedCardsResult,
      loading: linkedCardsLoading,
    } = useQuery<LinkedCardsQuery>(
      linkedCardsQuery,
      {
        env: getEnvironment(),
      },
      {
        pollInterval: 5000,
      }
    );

    const { result: paymentMethodsResult, variables } = useQuery<
      PaymentMethodsQuery,
      PaymentMethodsQueryVariables
    >(
      paymentMethodsQuery,
      {
        // Just a dummy value to get payment methods without waiting for the exact input
        amountInCent: 1000,
        shopperReference: state.card?.shopperReference,
        slug: merchant,
      },
      () => ({
        enabled: Boolean(state.card?.shopperReference),
      })
    );

    const linkedCards = useResult(linkedCardsResult);

    async function submitPayment() {
      if (paymentMethodsData && state.paymentMethod && state.card) {
        if (state.paymentMethod["applepay.token"]) {
          state.paymentMethod.applepayToken =
            state.paymentMethod["applepay.token"];
          delete state.paymentMethod["applepay.token"];
        }

        const { data } = await createPayment({
          input: {
            shopperReference: state.card.shopperReference,
            amountInCent: state.amountInCent,
            paymentMethod: state.paymentMethod,
            returnUrl: location.href,
            merchant,
          },
        });

        const {
          redirectURL,
          resultCode,
          shopperReference,
        } = data.createPayment;

        if (resultCode && resultCode.toLowerCase() === "authorised") {
          state.successMessage = { shopperReference };
        } else {
          window.location.href = redirectURL;
        }
      }
    }

    onMounted(() => {
      const query = queryString.parse(location.search);

      if (query && query.resultCode === "authorised") {
        state.successMessage = {
          shopperReference: query.shopperReference as string,
        };

        page.show("/merchant");
      }
    });

    const paymentMethodsData = useResult(paymentMethodsResult);

    watch(
      () => state.shopperReference,
      (shopperReference) => {
        if (shopperReference && linkedCards.value) {
          console.log(shopperReference, variables.value);
          state.card = linkedCards.value.find(
            (card) => card.shopperReference === shopperReference
          );

          variables.value.shopperReference = shopperReference;
        }
      }
    );

    const isApplePay = computed(
      () => state.card?.paymentMethod?.includes("applepay") || false
    );

    watch(paymentMethodsData, () => {
      if (
        paymentMethodsData.value &&
        linkedCards.value &&
        state.shopperReference
      ) {
        setupAdyen(
          ({ data }) => {
            state.paymentMethod = data.paymentMethod;

            // On applepay there is no submit button but just the one from apple, so trigger a payment on next render
            if (isApplePay.value) {
              submitPayment();
            }
          },
          paymentMethodsData.value,
          async (url: string, env: Environment) => {
            // const { data } = await validateMerchant({
            //   validationURL: url,
            //   env,
            // });
            // return data.validateAppleMerchant;
          },
          {
            userId: state.card.shopperReference,
            amount: 1000,
            type: isApplePay.value
              ? "applepay"
              : state.card.paymentMethod === "ideal"
              ? "ideal"
              : "card",
            getAmount: () => state.amountInCent,
          }
        );
      }
    });

    function mapLinkedCards(linkedCards: LinkedCardsQuery["admin_cards"], t) {
      return linkedCards.map((card) => ({
        key: card.shopperReference,
        value: card.shopperReference,
        text: t(
          { id: "transactions.form.userSelection" },
          {
            name: card.User?.email
              ? `${card.User?.email} (${card.shopperReference.slice(0, 6)})`
              : "no-email",
            amount: sumBy(card.Transaction, "points"),
            paymentMethod: card.paymentMethod || "ideal",
            cardSummary: JSON.parse(card.cardInfos || "{}").cardSummary,
          }
        ),
      }));
    }

    return {
      submitPayment,
      mapLinkedCards,
      linkedCardsLoading,
      linkedCards,
      state,
      isApplePay,
    };
  },
};
