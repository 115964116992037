import page from "page";
import { onMounted } from "vue";

import { logos } from "../components/layout.vue";
import { getMerchantSlug } from "./utils";

export default {
  template: require("./account-created.vue.html"),
  setup() {
    const merchant = getMerchantSlug();

    onMounted(() => {
      const qs = new URLSearchParams(location.search);
      if (qs.get("shopperReference")) {
        page.show(`/${merchant}/account-created`);
      }
    });

    return { logos, merchant };
  },
};
