import page from "page";

import { css, getMerchantSlug, getPaymentType, html } from "../pages/utils";

const template = html`
  <div class="ui padded stackable grid">
    <component is="style" v-html.once="style"></component>
    <slot></slot>
  </div>
`;

const style = (url: string, color: string) =>
  url
    ? `
  body {
    background-image: url("${url}");
  }

  .buttonArea {
    background: ${color};
  }

  .primary-button {
    color: ${color}
  }
`
    : `
  body {
    background: white;
  }
  .contentArea {border: 1px solid #3e4483;}
  .buttonArea {
    background: #3e4483;
  }

`;

export const backgrounds = {
  rituals: require("../../assets/rituals/background.jpg"),
  topgolf: require("../../assets/topgolf/background.jpg"),
  petsplace: require("../../assets/petsplace/background.jpg"),
  berden: require("../../assets/berden/background.jpg"),
};

export const logos = {
  rituals: require("../../assets/rituals/logo.png"),
  topgolf: require("../../assets/topgolf/logo.png"),
  loyyo: require("../../assets/loyyo/logo.png"),
  petsplace: require("../../assets/petsplace/logo.jpg"),
  berden: require("../../assets/berden/logo.png"),
};

export const mainColor = {
  topgolf: "#194c91",
  loyyo: "#3e4483",
  rituals: "#77675c",
  petsplace: "rgb(211, 0, 15)",
  berden: "rgb(63, 156, 63)",
};

export const mainLayout = {
  template,
  name: "main-layout",
  props: {
    navBar: { type: Boolean, default: true },
    showHeadline: { type: Boolean, default: true },
    merchant: String,
  },
  setup(props: { merchant: string }) {
    const merchant = getMerchantSlug();

    return {
      goTo(route: string) {
        page(route);
      },
      style: style(
        backgrounds[props.merchant || merchant],
        mainColor[props.merchant || merchant]
      ),
      scrollDown() {
        document
          .querySelector(".right-column")
          ?.scrollIntoView({ behavior: "smooth" });
      },
      paymentType: getPaymentType() || "no",
      location: window.location,
      isSafari: Boolean((window as any).safari),
    };
  },
};
