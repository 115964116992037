import { useI18n } from "vue-i18n";

import { html } from "../pages/utils";

const template = html` <template>{{ t(id, values) }}</template> `;

export const formatM = {
  name: "formatm",
  props: {
    id: {
      type: String,
      required: true,
    },
    values: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup() {
    const { t, d, locale } = useI18n();

    return { t, d, locale };
  },
  template,
};
