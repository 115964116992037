import { useMutation } from "@vue/apollo-composable";
import { gql } from "apollo-boost";

import { logos } from "../components/layout.vue";
import {
  StartAdyenPayment,
  StartAdyenPaymentVariables,
} from "./__generated__/StartAdyenPayment";
import { getMerchantSlug } from "./utils";

const startAdyenPayment = gql`
  mutation StartAdyenPayment($amount: Int!, $slug: String!) {
    pos_startPaymentAdyen(amount: $amount, merchantSlug: $slug) {
      payment
    }
  }
`;

export default {
  name: "MerchantPOS",
  template: require("./merchant-pos.vue.html"),
  setup() {
    const merchant = getMerchantSlug();

    const { mutate } = useMutation<
      StartAdyenPayment,
      StartAdyenPaymentVariables
    >(startAdyenPayment);

    async function handlePaymentStart() {
      try {
        const data = await mutate({ amount: 100, slug: merchant });

        console.log(data);
      } catch (error) {
        // some special format from POS errors containing + instead of whitespace
        alert(error.message.replace(/\+/g, " "));
      }
    }
    return { handlePaymentStart, merchant, logos };
  },
};
