import { useMutation } from "@vue/apollo-composable";
import { gql } from "apollo-boost";

import { logos } from "../components/layout.vue";
import {
  StartAdyenPayment,
  StartAdyenPaymentVariables,
} from "./__generated__/StartAdyenPayment";
import { getMerchantSlug } from "./utils";

const checkLoyaltyStatusMutation = gql`
  mutation CheckLoyaltyStatus($slug: String!) {
    pos_checkLoyaltyStatus(merchantSlug: $slug)
  }
`;

const showLoyaltyCodeMutation = gql`
  mutation ShowLoyaltyCode($slug: String!) {
    pos_showLoyaltyCode(merchantSlug: $slug)
  }
`;

export default {
  name: "MerchantPOSV2",
  template: require("./merchant-pos-v2.vue.html"),
  setup() {
    const merchant = getMerchantSlug();

    const { mutate: checkLoyaltyStatus } = useMutation<
      StartAdyenPayment,
      StartAdyenPaymentVariables
    >(checkLoyaltyStatusMutation);

    const { mutate: showLoyaltyCode } = useMutation<
      StartAdyenPayment,
      StartAdyenPaymentVariables
    >(showLoyaltyCodeMutation);

    async function checkLoyaltyStatusHandler() {
      try {
        const data = await checkLoyaltyStatus({
          slug: merchant,
        });

        console.log(data);
      } catch (error) {
        // some special format from POS errors containing + instead of whitespace
        alert(error.message.replace(/\+/g, " "));
      }
    }

    async function showLoyaltyCodeHandler() {
      try {
        const data = await showLoyaltyCode({ slug: merchant });

        console.log(data);
      } catch (error) {
        // some special format from POS errors containing + instead of whitespace
        alert(error.message.replace(/\+/g, " "));
      }
    }
    return {
      checkLoyaltyStatus: checkLoyaltyStatusHandler,
      showLoyaltyCode: showLoyaltyCodeHandler,
      merchant,
      logos,
    };
  },
};
