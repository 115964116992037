import { useMutation } from "@vue/apollo-composable";
import { gql } from "apollo-boost";
import page from "page";
import queryString from "query-string";
import { onMounted, reactive } from "vue";

import { logos } from "../components/layout.vue";
import { SaveUser, SaveUserVariables } from "./__generated__/SaveUser";
import { getMerchantSlug } from "./utils";

const saveUserMutation = gql`
  mutation SaveUser($email: String!, $customerKey: String, $slug: String!) {
    registerSimple(
      email: $email
      customerKey: $customerKey
      merchantSlug: $slug
    ) {
      id
      email
    }
  }
`;

export default {
  name: "UserForm",
  template: require("./user-form.vue.html"),
  setup() {
    const { mutate: saveUser } = useMutation<SaveUser, SaveUserVariables>(
      saveUserMutation,
      null
    );

    const merchant = getMerchantSlug();

    const query = queryString.parse(location.search);

    const state = reactive<{
      email: string;
      userToLink: null | string;
      hasRecurringDetails: boolean;
      paymentComplete: boolean;
      loading: boolean;
    }>({
      email: "",
      userToLink: null,
      hasRecurringDetails: false,
      paymentComplete: false,
      loading: false,
    });

    async function saveUserData() {
      // const client = resolveClient();
      //
      // const {data} = await client.query<UserQuery, UserQueryVariables>({
      //   query: usersQuery,
      //   variables: { email: state.email },
      // });
      //
      // console.log(data.doesUserExist);
      //
      // if (data.doesUserExist) {

      const customerKey = new URLSearchParams(location.search).get("sr");

      try {
        state.loading = true;
        const {
          data: {
            registerSimple: { id },
          },
        } = await saveUser({
          email: state.email,
          customerKey: customerKey || undefined,
          slug: merchant,
        });

        localStorage.setItem("userId", id);

        // if we have a customer key, then we know the person comes from a pos so redirect directly to the account page
        if (customerKey) {
          page.show(`/${merchant}/account-created?source=pos`);
        } else {
          page.show(`/${merchant}/cc`);
        }
      } catch (error) {
        alert(error.message);
      } finally {
        state.loading = false;
      }
    }

    onMounted(() => {
      if (query.resultCode === "authorised") {
        state.paymentComplete = true;
      }
    });

    return { saveUserData, state, logos, merchant };
  },
};
