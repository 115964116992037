import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { DefaultApolloClient } from "@vue/apollo-composable";
// import ApolloClient from "apollo-boost";
import page from "page";
import * as Vue from "vue";
import { markRaw, provide } from "vue";
// import * as VueApollo from "vue-apollo";
import * as VueIntl from "vue-i18n";

import * as components from "./components";
import { i18messages } from "./pages/i18messages";
import { baseURL } from "./pages/utils";

const client = new ApolloClient({
  uri: `${baseURL}graphql`,
  cache: new InMemoryCache(),
});

// export const apolloProvider = new VueApollo.ApolloProvider({
//   defaultClient: client,
// });

const i18 = VueIntl.createI18n({
  legacy: false,
  messages: i18messages,
});

const routes = {
  // "/": require("./pages/registration.vue").default,
  "/cc": require("./pages/registration.vue").default,
  "/ideal": require("./pages/registration.vue").default,
  "/applepay": require("./pages/registration.vue").default,
  "/paywithgoogle": require("./pages/registration.vue").default,
  "/card-landing": require("./pages/user-form.vue").default,
  "/": require("./pages/user-form.vue").default,
  "/account-created": require("./pages/account-created.vue").default,
  "/merchant-pos": require("./pages/merchant-pos.vue").default,
  "/merchant-pos-v2": require("./pages/merchant-pos-v2.vue").default,
  "/merchant": require("./pages/merchant.vue").default,
  "/ecom-merchant": require("./pages/super-merchant.vue").default,
};

// page.base();

const Page404 = require("./pages/404.vue").default;

export const app = Vue.createApp({
  el: "#app",
  setup() {
    provide(DefaultApolloClient, client);
    return {};
  },
  props: {
    route: String,
  },
  data: () => ({
    route: null,
    params: {},
    ViewComponent: { render: (h) => Vue.h("div", "loading...") },
  }),
  render() {
    return Vue.h(this.ViewComponent, {
      key: this.route,
      props: { params: this.params, title: "sdfsdf" },
    });
  },

  created() {
    for (let [routeName, component] of Object.entries(routes)) {
      page(`/:merchant${routeName}`, (state) => {
        this.params = state.params;
        this.route = routeName;
        this.ViewComponent = markRaw(component);
      });
    }

    page("*", () => (this.ViewComponent = markRaw(Page404)));

    page();
  },
});

app.use(i18);

Object.entries(components).forEach(([name, component]) => {
  app.component(component.name, markRaw(component));
});
